.edit-knockout-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;
  grid-auto-rows: minmax(100px, auto);
}

.edit-knockout-grid .col1 {
  grid-column: 1 / 2;
  grid-row: 1;
}
.edit-knockout-grid .col2 {
  grid-column: 2 / 2;
  grid-row: 1;
}
  