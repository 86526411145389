/* CSS for PinPad component */
.pin-pad-container {
    text-align: center;
}

.pin-pad-keys {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 20px;
  }
  
 .pin-pad-container button {
    font-size: 18px;
    padding: 10px;
    width: 10vh;
    height: 10vh;
  }
  
  .pin-display {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 1px solid grey;
    border-radius: 20px;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  