

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.fullscreen {
  top:30px;
  width: 100vw;
  height: calc(90vh);
}

.small {
  min-width: 40vw;
}

.modal-overlay .close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.modal button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 100px;
  width: 100px;
  font-weight: 500;
  vertical-align: middle;
}