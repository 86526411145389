/* ScoreboardOverlay.css */

.scoreboard-overlay .bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 12vh 12vh 0 0;
    display: grid;
    grid-template-columns: 15vw 70vw 15vw;
    align-items: center;
  }
    
  .scoreboard-overlay .score-container {
    display: grid;
    grid-template-columns: 25vw 7.5vw 5vw 7.5vw 25vw;
    align-items: center;
  }
  
  .scoreboard-overlay .score {
    text-align: center;
    font-size: 5vh;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .scoreboard-overlay .player {
    text-align: center;
    font-size: 3vh;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .scoreboard-overlay .vs {
    text-align: center;
    font-size: 2vh;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .scoreboard-overlay .player.home {
    text-align: right;
  }

  .scoreboard-overlay .player.away {
    text-align: left;
  }

  .scoreboard-overlay .match-summary {
    text-align: center;
    font-size: 3vh;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .scoreboard-overlay .bottom-image {
    text-align:center;
  }
  