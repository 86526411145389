/* src/Home.css */

.home-container {
  margin-top:50px;
  width: 100vw;
}

.home-container h2 {
  padding-top:10px;
  padding-left:10px;
  padding-right:10px;
}

.home-container-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100vw;
  align-items: center;
  gap:10px;
  padding-top:10px;
  padding-left:10px;
  padding-right:10px;
}
  
  .tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex-basis: 30vw;
    flex-shrink: 0;
    height: 150px;
    width: 150px;
  }

.tile p {
  margin: 0px;
}

  .tile img {
    height:75px;
  }
  
  h3 {
    background-color: var(--main-color);
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  