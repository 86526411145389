.setup {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setup-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.setup button {
    width: 100px;
    height: 100px;
}

.started {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.started .reset {
    position: absolute;
    top: 50px;
    right: 0px
}

.started .players {
    font-size: 5vh;
}
.started .players button{
    width: 5vh;
    height: 5vh;
}