/* src/Home.css */

.tabletimers-container {
  margin-top:50px;
  width: 100vw;
  vertical-align: middle;
}

.tabletimers-container h2 {
  padding-top:10px;
  padding-left:10px;
  padding-right:10px;
}

.tabletimers-container-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100vw;
  align-items: center;
  gap:10px;
  padding-top:10px;
  padding-left:10px;
  padding-right:10px;
}
  
.tabletimers-container  .tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex-basis: 30vw;
    flex-shrink: 0;
    height: 150px;
    width: 150px;
    text-align: center;
    vertical-align: middle;
  }
  
  .tabletimers-container .tile > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    height: 100px;
    width: 100px;
    font-weight: 500;
    vertical-align: middle;
  }

  .tabletimers-container .tile .busy {
    background-color: var(--main-color-4);
  }