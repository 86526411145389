.competition-container {
    margin-top:50px;
    padding:10px;
}

.no-nav {
  margin-top:0px;
}
  
  /* Style the tables */
  .competition-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  /* Style the table headers */
  .competition-container thead {
    background-color: #f2f2f2;
  }
  
  .competition-container th, .competition-container td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  .competition-container .finished {
    background-color: var(--main-color-3);
  }
  
  /* Add some spacing between cells */
  .competition-container th + th,
  .competition-container td + td {
    border-left: 1px solid #ddd;
  }
  
  /* Style the table cells */
  .competition-container td {
    vertical-align: middle;
  }
  
  /* Style the round headers */
  .competition-container h2 {
    color: #333;
    margin-top: 20px;
  }
  
  /* Style the match number cell */
  .competition-container td:first-child {
    font-weight: bold;
    background-color: var(--main-color);
    color: #FFFFFF;
  }  