.players-container {
  margin-top:50px;
  padding:10px;
}

.players-table {
  width: 100%;
  border-collapse: collapse;
}
  
  .players-table td {
    text-align: left;
    padding-left:10px;
    padding-right:10px;
  }
  
  .players-table th {
    background-color: var(--main-color);
    color: #fff;
  }
  
  .players-table tbody tr:hover {
    background-color: #e0f2f1; /* Lighter shade on hover */
  }