/* poker-timer.css */

.poker-timer-container {
    background-color: #f5f5f5;
    margin-top: var(--header);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 97vw;
    height: calc(100vh - var(--header) - 20px);

    display: grid; 
    grid-template-columns: 20% auto 20%; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap: 2px 2px; 
    grid-template-areas: 
      "entries    title   elasped"
      "remaining  level   break"
      "rebuys     level   payouts"
      "avgchips   blinds  payouts"
      "totalchips blinds  payouts"
      "totalpot   next    payouts";
    justify-items: stretch;
  }

  .poker-timer-container > div {
    background-color: var(--main-color-5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .poker-timer-container > div h4 {
    margin: 0;
    font-size: 3vh;
  }

  .poker-timer-container > div p {
    margin: 0;
    font-size: 3.5vh;
  }

  .poker-timer-container .level-name {
    grid-area: levelname;
  }

  .poker-timer-container .entries {
    grid-area: entries;
  }

  .poker-timer-container .remaining {
    grid-area: remaining;
  }

  .poker-timer-container .rebuys {
    grid-area: rebuys;
  }

  .poker-timer-container .avg-chips {
    grid-area: avgchips;
  }

  .poker-timer-container .total-chips {
    grid-area: totalchips;
  }

  .poker-timer-container .total-pot {
    grid-area: totalpot;
  }

  .poker-timer-container .level-remaining {
    background-color: var(--main-color);
    grid-area: level;
    font-size: 18vh;
  }

  .poker-timer-container .blinds {
    background-color: var(--main-color);
    grid-area: blinds;
  }

  .poker-timer-container .next {
    grid-area: next;
  }
  
  .poker-timer-container .elasped {
    grid-area: elasped;
  }

  .poker-timer-container .next-break {
    grid-area: break;
  }

  .poker-timer-container .payouts {
    grid-area: payouts;
  }