.matches-container {
  margin-top:50px;
  width: 100vw;
}

.matches-container h2 {
  padding:10px;
}

.matches-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    padding:10px;
  }
  
  .matches-table th, .people-table td {
    border: 1px solid var(--main-color);
    padding: 10px;
    text-align: left;
  }
  
  .matches-table th {
    background-color: var(--main-color);
    color: #fff;
  }
  
  .matches-table tbody tr:hover {
    background-color: #e0f2f1; /* Lighter shade on hover */
  }