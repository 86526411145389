.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top:50px;
    padding:10px;
  }
  
  .orders-table th, .orders-table td {
    padding: 10px;
    text-align: left;
  }
  
  .orders-table th {
    background-color: var(--main-color);
    color: #fff;
  }
  
  .orders-table tbody tr:hover {
    background-color: #e0f2f1; /* Lighter shade on hover */
  }

  @keyframes flash {
    0%, 50%, 100% {
      background-color: transparent; /* Set the background color when not flashing */
    }
    25%, 75% {
      background-color: #ffc3c395; /* Lighter shade on hover */
    }
  }
  
  .order-incomplete {
    animation: flash 3s infinite; /* Adjust the duration as needed */
  }
  
