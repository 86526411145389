/* Assuming that you have a common CSS file for styling */
/* Import your common styles here */

.edit-table-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:50px;
    padding:10px;
  }
  
  .edit-table-form label {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .edit-table-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid var(--main-color);
    border-radius: 4px;
  }