/* Scoreboard.css */

.scoreboard-container {
    background-color: #f5f5f5;
    margin-top: 50px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 95vw;
  }
  
  .scoreboard-create {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .scoreboard-scores {
    display: flex;
    justify-content: space-around;
  }
  
  .scoreboard-score {
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    width: 42vw;
    height: 75vh;
  }
  
  .score {
    font-size: 40vh;
    font-weight: bold;
    margin: 0 10px;
  }
  
  .scoreboard-score button {
    font-size: 5vh;
    width: 5vw;
    height: 5vw;
    margin: 5px;
  }
  
  .scoreboard-container-action {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 40vh;
    left: calc(50vw - 100px);
  }
  