.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--main-color);
    color: #fff;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 30px;
    z-index: 1000; /* Adjust the z-index as needed */
  }
  
  .nav-bar img {
    width:40px;
  }

  .nav-list {
    list-style: none;
    margin: 0;
    margin-left:20px;
    padding: 0;
  }

  .nav-bar ul {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Centers items vertically */
    margin: 0;
    padding: 0;
    list-style: none;
}
  
  .nav-bar li {
    display: flex;
    align-items: center; /* Centers content of each li */
    justify-content: center; /* Optional: Centers text horizontally */
    padding: 10px;
}
  
  /* Add additional styles as needed */
  