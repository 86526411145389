.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.login-container h2 {
margin-bottom: 20px; /* Add some spacing below the heading */
}

.login-form {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form items horizontally */
}

.login-form label {
margin-bottom: 8px;
}

.login-form input {
padding: 8px;
margin-bottom: 16px;
margin-left: 10px;
width: 40vw
}