.table-table {
    width: 100%;
    border-collapse: collapse;
    margin-top:50px;
    padding:10px;
  }
  
  .table-table th, .people-table td {
    border: 1px solid var(--main-color);
    padding: 10px;
    text-align: left;
  }
  
  .table-table th {
    background-color: var(--main-color);
    color: #fff;
  }
  
  .table-table tbody tr:hover {
    background-color: #e0f2f1; /* Lighter shade on hover */
  }
  