:root {
  /*--main-color: #1A237E;
  --main-color-2: #303F9F;*/
  --main-color: #1a7e59;
  --main-color-2: #22aa78;
  --main-color-3: #303f9f64;
  --main-color-4: #1a7e59a3;
  --main-color-5: #1a7e595c;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin:5px;
}

button:not(:disabled):hover {
  background-color: var(--main-color-2);
}

a {
    cursor: pointer;
}

button:disabled {
  background-color: #999;
  color: #666;
  cursor: not-allowed;
}