/* Assuming that you have a common CSS file for styling */
/* Import your common styles here */

.edit-competition-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: 10px;
  }

.edit-competition-container {
  font-weight: 600;
}
  
.edit-competition-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /*gap: 50px;*/
}

.edit-competition-grid .col1 {
  grid-column: 1 / 2;
  grid-row: 1;
}
.edit-competition-grid .col2 {
  grid-column: 2 / 2;
  grid-row: 1;
}
  
  .edit-match-form label {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .edit-match-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #4caf50;
    border-radius: 4px;
  }
  