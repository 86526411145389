/* Assuming that you have a common CSS file for styling */
/* Import your common styles here */

.edit-player-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:50px;
    padding:10px;
  }
  
  .edit-player-form label {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .edit-player-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #4caf50;
    border-radius: 4px;
  }
  