  /* Style the table headers */
  .competition-roundrobin-table td {
text-align: center;
  }

  .competition-roundrobin-table thead {
    background-color: var(--main-color);
    color: #fff;
  }

  .competition-roundrobin-table th {
    background-color: var(--main-color);
    color: #fff;

  }